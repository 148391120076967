import React, { useState } from "react";

import { Modal, Button, Toast } from 'react-bootstrap';

import "./custom.scss";

const CiDonutsResultat = (props) => {

    let ci_global = props.data;
    let ci_resultats = props.resultats;

    let ci_p1,ci_p2,ci_p3,ci_p4,ci_p5,ci_p6;

    ci_p1 = props.ci_p1;
    ci_p2 = props.ci_p2;
    ci_p3 = props.ci_p3;
    ci_p4 = props.ci_p4;
    ci_p5 = props.ci_p5;
    ci_p6 = props.ci_p6;

    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [industrie, setIndustrie] = useState("Retail & CPG");
    const [website, setWebsite] = useState("");

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    const [showError, setShowError] = useState(false);
    const toggleError = () => setShowToast(!showError);

    const handleEmail = (event) => setEmail(event.target.value);
    const handleIndustrie = (event) => setIndustrie(event.target.value);
    const handleWebsite = (event) => setWebsite(event.target.value);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendDataToHubspot = () => {
        console.log("le mail est : " + email);
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"fields":[{"name":"email","value":email},{"name":"website","value":website},{"name":"ci_global", "value":ci_global},{"name":"ci_p1", "value":ci_p1},{"name":"ci_p2", "value":ci_p2},{"name":"ci_p3", "value":ci_p3},{"name":"ci_p4", "value":ci_p4},{"name":"ci_p5", "value":ci_p5},{"name":"ci_p6", "value":ci_p6},{"name":"ci_industry","value":industrie},{"name":"ci_p1_q1","value":ci_resultats[0]},{"name":"ci_p1_q2","value":ci_resultats[1]},{"name":"ci_p1_q3","value":ci_resultats[2]},{"name":"ci_p2_q1","value":ci_resultats[3]},{"name":"ci_p2_q2","value":ci_resultats[4]},{"name":"ci_p2_q3","value":ci_resultats[5]},{"name":"ci_p3_q1","value":ci_resultats[6]},{"name":"ci_p3_q2","value":ci_resultats[7]},{"name":"ci_p3_q3","value":ci_resultats[8]},{"name":"ci_p3_q4","value":ci_resultats[9]},{"name":"ci_p4_q1","value":ci_resultats[10]},{"name":"ci_p4_q2","value":ci_resultats[11]},{"name":"ci_p4_q3","value":ci_resultats[12]},{"name":"ci_p5_q1","value":ci_resultats[13]},{"name":"ci_p5_q2","value":ci_resultats[14]},{"name":"ci_p5_q3","value":ci_resultats[15]},{"name":"ci_p6_q1","value":ci_resultats[16]},{"name":"ci_p6_q2","value":ci_resultats[17]},{"name":"ci_p6_q3","value":ci_resultats[18]}],"context":{"pageUri":"www.conversational-index.com/fr/questionnaire","pageName":"Conversational Index"}}),
            redirect: 'follow'
        };
        fetch('https://api.hsforms.com/submissions/v3/integration/submit/403167/0a79ea81-b941-4070-a93b-0879e087234c', requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .then(handleClose)
        .then(toggleToast)
        .catch(error => toggleError);

    }
    
    return (<>

            <div
            style={{
                position: 'fixed',
                top: 10,
                right: 10,
                zIndex: 99
            }}
            >
                <Toast show={showToast} onClose={toggleToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong>Merci !</strong>
                    </Toast.Header>
                    <Toast.Body>Vous allez recevoir un e-mail avec votre secteur ainsi que des axes d'amélioration.</Toast.Body>
                </Toast>

            </div>

            <div
            style={{
                position: 'fixed',
                top: 10,
                right: 10,
                zIndex: 99
            }}
            >
                <Toast show={showError} onClose={toggleError} delay={5000} autohide>
                    <Toast.Header>
                        <strong>Erreur :(</strong>
                    </Toast.Header>
                    <Toast.Body>Impossible d'enregistrer vos resultats #ERR02 - contact@iadvize.com</Toast.Body>
                </Toast>

            </div>


            <Modal
            className="modalMain"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            >
            <Modal.Body className="modalBodyScore">
                <img id="close" onClick={handleClose} src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20865.png"></img>
                
                <div className="leftModalScore">
                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/image%2012.png"></img>
                </div>

                <div className="rightModalScore">
                    <div className="modalTitle">Découvrez vos résultats détaillés</div>
                    <div className="formInner">
                        <form action="" method="get" className="formLate">
                        <div className="formSet">
                                <span>Industrie*</span>
                                <select id="industries" name="industries" value={industrie} onChange={handleIndustrie}>
                                    <option value="Retail & CPG">Distribution et produits de grande consommation</option>
                                    <option value="Finance - Health Insurance">Finance, santé & Assurance</option>
                                    <option value="Automotive">Automobile</option>
                                    <option value="Telco & Consumer Services">Télécom & services</option>
                                    <option value="Travel & Tourism">Tourisme & Voyages</option>
                                    <option value="Other">Autres</option>
                                </select>
                            </div>

                            <div className="formSet">
                                <span>website*</span>
                                <input type="text" name="website" id="website" value={website} onChange={handleWebsite} placeholder="website" required/>
                            </div>

                            <div className="formSet">
                                <span>Adresse email*</span>
                                <input type="email" name="email" id="email" value={email} onChange={handleEmail} placeholder="Email professionnel" required/>
                            </div>



                            <div className="formSet">
                                <div className="rgpd">
                                    <input type="checkbox" id="scales" name="scales" required/>
                                    <label>J’ai lu et accepte la <a href="https://privacy.iadvize.com/fr/">politique d’iAdvize en matière de protection des données à caractère personnel</a></label>
                                </div>
                            </div>



                            <div className="formSet">
                                <Button id="goToLead" className="ctaFinal" onClick={sendDataToHubspot} disabled={!email || !website || !industrie }>
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Arrow%202.png"></img>
                                    Recevoir mon rapport
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>



            </Modal.Body>
            </Modal>

            <div className="resultatsDetails">
                        
                <div className="donutsInner">
                    <img id="logoCi" src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20878.png"></img>
                    <div className= {[`pie`, `p${ci_global}`].join(' ')}></div>
                </div>
                
                <div className="text">
                    <h1>Découvrez vos résultats détaillés :</h1>
                    <p>Entrez votre contact ci-dessous pour recevoir par e-mail une vue complète de vos résultats.</p>
                    <div className="tick">
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201-1.png"></img>
                        <span>Le score général et détaillé des leaders de votre secteur ainsi que les moyennes marché</span>
                    </div>
                    <div className="tick">
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201-1.png"></img>
                        <span>Vos forces et axes d’amélioration sur chacun des piliers</span>
                    </div>
                    <div className="tick">
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201-1.png"></img>
                        <span>Des recommandations illustrées par des cas d’usages concrets</span>
                    </div>

                    <Button className="cta" onClick={handleShow}>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Arrow%202-1.png"></img>
                        Recevoir mon rapport
                    </Button>

                </div>

            </div>
    
    </>)
        
}

export default CiDonutsResultat
