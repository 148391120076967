import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import { Radar } from 'react-chartjs-2';
import { Chart } from "react-chartjs-2";


import "./../components/conversational-index/france/ci-resultat/ci-donuts.scss";

import CiDonutsResultat from "../components/conversational-index/france/ci-resultat-donuts/ci-resultat-donuts"
import CiCtaResultat from "../components/conversational-index/france/ci-resultat-cta/ci-resultat-cta"

import Footer from "./../components/conversational-index/france/ci-footer/ci-footer";

import SEO from "../components/seo";

class ResultatPageFr extends Component {


    render() {


        Chart.defaults.global.legend.display = false;

        let resultats;
        let finalResultats = [];
        let ci_global = 0;

        let classes = "";

        let ci_p1, ci_p2, ci_p3, ci_p4, ci_p5, ci_p6;

        if (typeof (this.props.location.state) !== 'undefined' && this.props.location.state !== null) {


            resultats = this.props.location.state.resultatsArray;
            console.log(resultats)

        } else {
            resultats = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }

        for (let i = 0; i < 19; i++) {
            ci_global = ci_global + resultats[i];
        }

        //sum the global score from the resultats array by adding each results 
        ci_global = Math.round(ci_global);

        classes = `pie p${ci_global}`

        ci_p1 = Math.round((resultats[0] + resultats[1] + resultats[3]) * 8.33);
        finalResultats.push(ci_p1);

        ci_p2 = Math.round((resultats[4] + resultats[5] + resultats[6]) * 8.33);
        finalResultats.push(ci_p2);

        ci_p3 = Math.round((resultats[7] + resultats[8] + resultats[9] + resultats[10]) * 6.25);
        finalResultats.push(ci_p3);

        ci_p4 = Math.round((resultats[11] + resultats[12] + resultats[12]) * 8.33);
        finalResultats.push(ci_p4);

        ci_p5 = Math.round((resultats[13] + resultats[14] + resultats[15]) * 8.33);
        finalResultats.push(ci_p5);

        ci_p6 = Math.round((resultats[16] + resultats[17] + resultats[18]) * 8.33);
        finalResultats.push(ci_p6);

        const data = {
            labels: ['Expertise, empathie & authenticité', 'Scalabilité', 'Stratégie et Pilotage', 'Engagement et personnalisation', 'Messaging riche et facile à utiliser', 'Fluidité & omnicanalité'],
            datasets: [
                {
                    label: '-',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                    pointHoverBackgroundColor: 'transparent',
                    pointHoverBorderColor: 'transparent',
                    data: [10, 30, 50, 80, 90, 100]
                },
                {
                    label: 'My Conversational Index',
                    backgroundColor: 'rgba(27, 199, 78, 0.4)',
                    borderColor: 'rgb(27, 199, 78)',
                    pointBackgroundColor: 'rgb(27, 199, 78)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(27, 199, 78)',
                    data: finalResultats
                }
            ]
        };




        return (
            <>

                <SEO title="Conversational Maturity Index : Évaluez la maturité conversationnelle de votre entreprise." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index%20(4).png" lang="fr" description="En réalisant la version en ligne de cet audit, obtenez un aperçu objectif de votre maturité conversationnelle en moins de 10 minutes. Recevez une analyse présentant vos forces et vos axes d’amélioration, ainsi que votre situation par rapport à votre secteur." />
                <section className="resultatSection">
                    <div className="inner">

                        <img id="mainLogo" src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20879.png"></img>

                        <CiDonutsResultat data={ci_global} resultats={resultats} ci_p1={ci_p1} ci_p2={ci_p2} ci_p3={ci_p3} ci_p4={ci_p4} ci_p5={ci_p5} ci_p6={ci_p6} />

                        <div className="details">
                            <h2>Détails</h2>
                            <div className="inner">

                                <div className="leftSideBloc">
                                    <div className="leftSide">
                                        <h3>Détails des réponses</h3>
                                        <Radar className="radar" data={data} />
                                    </div>

                                    <CiCtaResultat data={ci_global} resultats={resultats} ci_p1={ci_p1} ci_p2={ci_p2} ci_p3={ci_p3} ci_p4={ci_p4} ci_p5={ci_p5} ci_p6={ci_p6} />


                                </div>

                                <div className="right">
                                    <div className="scoresInner">

                                        <div id="detailsH2">Vos scores sur les 6 piliers</div>

                                        <span>Meilleurs scores possibles : 100</span>

                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Expertise-1.png"></img>
                                                <div className="legend">Expertise, empathie & authenticité</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p1}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Scalabilite%CC%81-1.png"></img>
                                                <div className="legend">Scalabilité</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p2}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Projet%20dentreprise-1.png"></img>
                                                <div className="legend">Stratégie et Pilotage</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p3}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Personnalisation-1.png"></img>
                                                <div className="legend">Engagement et personnalisation</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p4}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Messaging%20riche-1.png"></img>
                                                <div className="legend">Messaging riche et facile à utiliser</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p5}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Omnicanal-1.png"></img>
                                                <div className="legend">Fluidité & omnicanalité</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p6}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>



                </section>

                <Footer />

            </>
        )
    }

}

export default ResultatPageFr